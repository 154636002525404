export const API_TIMEOUT = 60 * 1000;
export const GENERATION_SETTING_CODE = 'user.setting';
export const ACCESS_TOKEN = 'pk.eyJ1Ijoic2hpeXVhbnl1ZSIsImEiOiJjbGU0ODJkejAwMGV1M25temFoM2dsNTNhIn0.7WOXgUcUT4ymbaH9jx5C2A';
export const PAGE_SIZE = 20;
// api 接受的日期格式
export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const ONE_DAY = 24 * 60 * 60 * 1000;
export const ONE_MINUTE = 60 * 1000;

export const DEFAULT_LANGUAGE = {
    key: 'EN_US',
    text: 'English'
};
