/**
 * 服务端Url
 * @returns
 */
export const serverUrl = () => {
    return import.meta.env.VITE_SERVER_URL;
};

export const libUrl = () => {
    return import.meta.env.VITE_LIB_URL;
};
