import { TQ_Get } from '@admin/utils/tools/axios';
import { memoryCacheWrap } from '@utils/width-memory-cache';

// 语言包
export const getLanguagePackage = memoryCacheWrap(() => TQ_Get('/pc/i18n/configurations', { _: Date.now() }));

// 支持的语言列表
export const getLanguageList = memoryCacheWrap(() => TQ_Get('/pc/i18n/languages', { _: Date.now() }));

// 全量服务端语言
export const getAllLanguageList = () => TQ_Get('/base/language/list?code=&zhStr=&pageNo=1&pageSize=10000&typeName=pc', { _: Date.now() });

// odm语言列表
export const getCountryLanguageList = params => TQ_Get('/base/language/getLanguageList', params);
