/**
 * @description 带内存缓存
 */
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import isFunction from 'lodash-es/isFunction';
// import { ONE_MINUTE } from './constant';

// 内存缓存
const memoryCacheMap = new Map();

// 自动清除缓存时间
const autoClearCacheInterval = 2000;

// 从 localStorage 获取当前的租户 id
let uid = localStorage.getItem('tenantId') || '';

// 删除内存缓存
export const removeMemoryCache = (callback, ...args) => {
    if (!callback) {
        // 表示全清除
        memoryCacheMap.clear();
    } else {
        const memoryCacheList = memoryCacheMap.get(callback) || [];

        // 没有入参，表示全部删除
        if (args.length === 0) {
            memoryCacheList.length = 0;
        }
        const nextMemoryCacheList = memoryCacheList.filter(item => !isEqual(item.args, args));

        memoryCacheMap.set(callback, nextMemoryCacheList);
    }
};

// 判断 uid 是否有变化
const removeCacheWhenUidChange = () => {
    const currentUid = localStorage.getItem('tenantId') || '';

    if (currentUid !== uid) {
        uid = currentUid;
        // 清除所有缓存
        removeMemoryCache();
    }
};

// 给目标函数加上内存缓存策略
export const withMemoryCache = async (callback, maxCacheCount = 5, ...args) => {
    removeCacheWhenUidChange();
    const memoryCacheList = memoryCacheMap.get(callback) || [];
    // 是否有缓存
    const hasCache = memoryCacheList.length > 0;
    // 目标缓存
    let cache = null;

    if (hasCache) {
        ({ cache } = memoryCacheList.find(item => isEqual(args, item.args)) || {});
    }
    if (!cache) {
        // 没有命中缓存，需要调用 callback 方法返回
        const result = callback(...args);
        const isPromise = Boolean(result?.then);

        if (isPromise) {
            // 表示是一个 promise
            cache = result;
            // 如果报错，不做缓存
            result.catch(() => {
                removeMemoryCache(callback, ...args);
            });
        } else {
            // 非 Promise，创建一个 Promise
            cache = Promise.resolve(result);
        }

        if (memoryCacheList.length === maxCacheCount) {
            // 删除头部节点
            memoryCacheList.shift();
        }

        // 新增缓存
        const cloneArgs = cloneDeep(args);

        memoryCacheList.push({
            cache,
            args: cloneArgs
        });

        // 定时删除缓存
        if (autoClearCacheInterval > 0) {
            setTimeout(() => {
                removeMemoryCache(callback, ...cloneArgs);
            }, autoClearCacheInterval);
        }

        // 存进内存
        memoryCacheMap.set(callback, memoryCacheList);
    }
    return cache;
};

// 给目标函数添加一层包裹（自动加上 withCache & removeCache 静态方法）
export const memoryCacheWrap = (callback, maxCacheCount) => {
    if (!isFunction(callback)) {
        throw new Error('参数不正确，期望入参类型为函数');
    }
    const memo = (...args) => withMemoryCache(callback, maxCacheCount, ...args);
    const clearMemo = (...args) => {
        removeMemoryCache(callback, ...args);
        /**
         * 这里返回 Promise.resolve() 的作用如下：
         * 如果遇到同步代码中同时执行多次 clearMemo + memo/one 调用，那么 memo/one 将起不到归并请求的作用。
         * 加上 Promise.resolve() 后，可以通过 await clearMemo(); 的调用方式将原来的同步代码分片成几个微任务
         */
        return new Promise(resolve => {
            setTimeout(() => resolve({}), 100);
        });
    };
    // 相同参数的调用在上一次调用未返回时，应该合并成同一个请求
    const one = async (...args) => {
        try {
            const result = await withMemoryCache(callback, maxCacheCount, ...args);

            // 清除状态
            clearMemo(...args);
            return result;
        } catch (err) {
            // 清除状态
            clearMemo(...args);
            throw err;
        }
    };
    // 统一处理的轮询
    const loop = (...args) => {
        let delay = 3000; // 时间间隔为 3s
        let sto = null;
        const returnResult = {};
        const catchHanlers = [];
        const stop = () => {
            if (sto) {
                clearTimeout(sto);
            }
            return returnResult;
        };
        const iterate = handleIterate => {
            // 解决 TS 报错
            (async () => {
                try {
                    const result = await one(...args);

                    handleIterate(result, stop);
                } catch (err) {
                    catchHanlers.forEach(handler => handler(err, stop));
                }
            })();
            // 进入下一次请求
            sto = setTimeout(() => {
                iterate(handleIterate);
            }, delay);
            return returnResult;
        };
        const set = intervale => {
            delay = intervale;
            return returnResult;
        };

        // 捕获错误
        const catchError = catchHanler => {
            if (catchHanler) {
                catchHanlers.push(catchHanler);
            }
            return returnResult;
        };

        Object.assign(returnResult, {
            set,
            stop,
            iterate,
            catchError
        });
        return returnResult;
    };

    Object.assign(callback, {
        one,
        loop,
        memo,
        clearMemo
    });

    return callback;
};
