import { i18next } from '../languages/i18n';
import { Base64 } from 'js-base64';
import md5 from 'md5';
import dayjs from 'dayjs';
import { regionText } from '@admin/utils/customize';

/**
 * 延迟时间
 * @param path
 * @returns {Promise<unknown>}
 */
export const waitTime = path => {
    return new Promise(resolve => setTimeout(() => resolve(path), 1000));
};

/**
 * 在外部 js/ts 中使用翻译方法
 * @param key 翻译字段
 */
export const translation = (key, params = {}) => {
    // if (key === '' || key === null || key === undefined) return '';

    return i18next.t(key, params);
};

/**
 * 滚动条缓慢滚动
 * @param element  DOM 元素
 * @param duration 滚动时间
 */
export const scrollToBottom = (element, duration) => {
    const start = element.scrollTop;
    const target = element.scrollHeight - element.clientHeight;
    const startTime = performance.now();

    function scroll(timestamp) {
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);

        element.scrollTop = start + (target - start) * progress;

        if (progress < 1) {
            requestAnimationFrame(scroll);
        }
    }

    requestAnimationFrame(scroll);
};

/**
 * base64加密
 * @param str
 * @returns {string}
 */
export const base64Encoded = str => {
    if (typeof str === 'string' || typeof str === 'boolean') {
        return btoa(str);
    }

    if (typeof str === 'object') {
        const jsonString = JSON.stringify(str); // 将对象转换为 JSON 字符串

        return btoa(jsonString); // 对 JSON 字符串进行 Base64 编码
    }
};

/**
 *  base64 解密
 * @param str
 * @return {string}
 */
export const base64Uncode = str => {
    return atob(str);
};

export let deviceMetaInfo = null;
// 获取设备类型
const deviceMeta = () => {
    fetch('/_device_meta_.json')
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            deviceMetaInfo = data;
        });
};

deviceMeta();

// 动态创建链接
export const dyLink = (cssLink, favicon) => {
    const link = document.createElement('link');

    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', cssLink);
    document.getElementsByTagName('head')[0].appendChild(link);

    document.querySelector('link[rel="icon"]').setAttribute('href', favicon);
};

/**
 * password crypto
 * @param str pwd
 * @returns
 */
export const cryptoStr = (str = '') => {
    return Base64.encode(md5(str));
};

// md5 pwd
export const md5Str = (str = '') => {
    return md5(str);
};

/**
 * 计算小数点后有几位
 * @param value
 * @return {number|*}
 * @example
 * countDecimalPlaces(curItem.settingValueDesc)
 */
export const countDecimalPlaces = value => {
    const v = value.split(',')[0].replace('[', '').replace('-', '');

    const match = v.match(/\.(\d+)$/);

    if (match) {
        return match[1].length;
    }
    return 0;
};

/**
 * 强制保留小数点几位
 * @param num  数字
 * @param length  需要保存小数点几位
 * @return {string} 数字
 * @example
 * console.log(numWay(2,2));
 * console.log(numWay(2.1,2));
 * console.log(numWay(2.12,2));
 * console.log(numWay(2.123,2));
 */
export const numWay = (num, length) => {
    let f = parseFloat(num);

    if (isNaN(f)) {
        return false;
    }

    f = Math.round(num * 100) / 100;

    let s = f.toString();

    let rs = s.indexOf('.');

    if (rs < 0) {
        rs = s.length;

        s += '.';
    }

    while (s.length <= rs + length) {
        s += '0';
    }

    return s;
};

/**
 * 判断值是否为空 如果有空数据  则返回 '--'
 * @param str
 * @return {string}
 * @example
 * isEmpty('123');
 */
export const isEmpty = str => {
    return str || '--';
};

/**
 * 判断json对象中所有字段是否都为空
 * @param obj
 * @return {boolean}
 * @example
 * isObjectEmpty({
 *  "name": "",
 *   "age": null,
 *   "address": {
 *     "city": "",
 *     "zip": null
 *   },
 *   "contacts": []
 * })
 */
export const isObjectEmpty = obj => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            if (value === null || value === undefined) {
                // 如果值为 null 或 undefined，不处理，继续遍历
                continue;
            }

            if (typeof value === 'object') {
                // 如果值是对象，则递归检查该对象
                if (!isObjectEmpty(value)) {
                    return false; // 如果子对象不为空，则整个对象不为空
                }
            } else if (value !== '') {
                // 如果值不是空字符串，则整个对象不为空
                return false;
            }
        }
    }
    return true; // 所有字段都为空或者是 null/undefined，整个对象为空
};

/**
 * 判断多维数组是否为空
 * @param arr
 * @return {boolean}
 * @example
 * isNestedArrayAllNull([
 *   [null, null, null],
 *   [null, null, null],
 *   [null, null, null]
 * ])
 */
export const isNestedArrayAllNull = arr => {
    for (const element of arr) {
        if (Array.isArray(element)) {
            // 如果当前元素是数组，则递归检查该数组
            if (!isNestedArrayAllNull(element)) {
                return false;
            }
        } else if (element !== null) {
            // 如果当前元素不是数组且不为 null，则整个数组不全为 null
            return false;
        }
    }
    return true; // 所有元素都为 null
};

/**
 * 判断传入的是否为时间
 * @param dateString
 * @return {boolean}
 */
export const isDateStringValid = dateString => {
    const dateObject = new Date(dateString);

    return dateObject.toString() !== 'Invalid Date';
};

/**
 * 对比两个数组长度和值是否相同
 * @param arr1
 * @param arr2
 * @return {*|boolean} true/false
 */
export const areArraysEqual = (arr1, arr2) => {
    // 检查长度是否相同
    if (arr1.length !== arr2.length) {
        return false;
    }

    // 使用 every 方法比较数组的每个元素
    return arr1.every((value, index) => value === arr2[index]);
};

/**
 * 对象根据某个字段去重
 * @param dataArray
 * @param fieldName
 * @return {*[]}
 */
export const removeDuplicatesByFieldName = (dataArray, fieldName) => {
    const uniqueSet = new Set();
    const uniqueArray = [];

    dataArray.forEach(item => {
        // 检查字段值是否为空字符串
        if (item[fieldName] !== '' && !uniqueSet.has(item[fieldName])) {
            uniqueSet.add(item[fieldName]);
            uniqueArray.push(item);
        }
    });

    return uniqueArray;
};

// 禁用未来日期
export const disabledDate = current => {
    // 获取当前日期
    const today = dayjs().endOf('day');

    // 禁用未来日期
    return current && current > today;
};

// 禁用过去日期
export const disabledOverDate = current => {
    // 禁用未来日期
    return current && current < dayjs().subtract(1, 'days');
};

export const disabledDate_7 = (current, { from }) => {
    if (from) {
        return Math.abs(current.diff(from, 'days')) >= 7;
    }
    return disabledDate(current);
};

export const disabledDate_100 = (current, { from }) => {
    if (from) {
        return Math.abs(current.diff(from, 'days')) >= 100;
    }
    return disabledDate(current);
};

const range = (start, end) => {
    const result = [];

    for (let i = start; i <= end; i++) {
        result.push(i);
    }

    return result;
};

// 禁用过去时分秒
export const disabledDateTime = (dates, partial) => {
    const hours = dayjs().hour(); // 0~23

    const minutes = dayjs().minute(); // 0~59

    const seconds = dayjs().second(); // 0~59

    // 当日只能选择当前时间之后的时间点

    if (dates && dayjs(dates[1]).date() === dayjs().date() && partial === 'end') {
        return {
            disabledHours: () => range(hours + 1, 23),

            disabledMinutes: () => range(minutes + 1, 59),

            disabledSeconds: () => range(seconds + 1, 59)
        };
    }
};

/**
 * 获取第一个表格的可视化高度
 * @param {*} extraHeight 额外的高度(表格底部的内容高度 Number类型,默认为74)
 * @param {*} id 当前页面中有多个table时需要制定table的id
 */
export const getTableScroll = ({ extraHeight = 0, id }) => {
    const Y = document.getElementById(id);

    if (Y) {
        return `calc(100% - 42px - ${extraHeight}px)`;
    }
};

// 判断数组是否全为空
export const allEmpty = arr => {
    return arr.every(element => element === '');
};

// 去除数组为空的数据
export const excludeEmpty = arr => {
    return arr.filter(item => item !== null && item !== undefined && item !== '');
};

/**
 * 去重 方法
 * return 去重前的数据和索引位置
 */
export const deEmphasize = data => {
    // 创建一个空对象用于记录每个值对应的索引数组
    const indexMap = {};

    // 遍历原始数据数组
    data.forEach((value, index) => {
        // 如果值在索引映射中不存在，创建一个空数组来存储索引
        if (!indexMap[value]) {
            indexMap[value] = [index];
        } else {
            // 如果值已经存在于索引映射中，将当前索引添加到对应的数组中
            indexMap[value].push(index);
        }
    });

    return indexMap;
};

// leader-line 配置
export const leaderLineOptions = () => {
    return {
        dash: {
            animation: {
                timing: 'linear', // 'ease' | 'linear' | 'ease-in' | 'ease-out' | 'ease-in-out' | Array<number>;
                duration: 1600
            },
            len: 22,
            gap: 18
        },
        path: 'grid', // 'straight' | 'arc' | 'fluid' | 'magnet' | 'grid';
        size: 10, // 线宽度
        endPlug: 'behind',
        // 是否开启指引线边框
        outline: true,
        // 流动块颜色
        color: '#676CC4',
        // 指引线颜色
        outlineColor: regionText() === 'solinteg' ? '#E1E2F3A5' : '#D2ECCA',
        // 指引线线宽
        outlineSize: 2,
        startSocket: 'right',
        endSocket: 'left',
        // dropShadow: { color: 'rgba(17, 148, 51, .8)', dx: 0, dy: 0 },
        endPlugColor: regionText() === 'solinteg' ? 'rgba(103,108,196,1)' : 'rgba(101,194,70,1)',
        startPlugColor: regionText() === 'solinteg' ? 'rgba(103,108,196,0.25)' : 'rgba(101,194,70,0.25)',
        gradient: true
    };
};

// 禁止(ctrl 和 + || -)缩放
export const prohibitScaling = () => {
    const keyCodeMap = {
        // 91: true, // command
        61: true,
        107: true, // 数字键盘 +
        109: true, // 数字键盘 -
        173: true, // 火狐 - 号
        187: true, // +
        189: true // -
    };

    // 覆盖ctrl||command + ‘+’/‘-’
    document.onkeydown = function (event) {
        const e = event || window.event;
        const ctrlKey = e.ctrlKey || e.metaKey;

        if (ctrlKey && keyCodeMap[e.keyCode]) {
            e.preventDefault();
        } else if (e.detail) {
            // Firefox
            event.returnValue = false;
        }
    };
    // 覆盖鼠标滑动
    document.body.addEventListener(
        'wheel',
        e => {
            if (e.ctrlKey) {
                if (e.deltaY < 0) {
                    e.preventDefault();
                    return false;
                }
                if (e.deltaY > 0) {
                    e.preventDefault();
                    return false;
                }
            }
        },
        { passive: false }
    );
};

// 动态获取public下图片  imgUrl: '/charging/zh/tips.svg'
export const loadPicture = imgUrl => {
    const pathnameArr = location.pathname.split('/');
    const realPathArr = [];

    pathnameArr.forEach(item => {
        if (item && item.slice(-5) !== '.html') {
            realPathArr.push(item);
        }
    });
    let realPath = location.origin + '/';

    if (realPathArr.length > 0) {
        realPath = realPath + realPathArr.join('/') + '/';
    }

    return new URL(imgUrl, realPath).href;
};
