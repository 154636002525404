import store from 'store';

// pdf logo图片
import dowell_pdf from '@admin/assets/edition/pdfLogo/dowell/logo.png';
import etek_pdf from '@admin/assets/edition/pdfLogo/etek/logo.png';
import selfa_pdf from '@admin/assets/edition/pdfLogo/selfa/logo.png';
import sheenplus_pdf from '@admin/assets/edition/pdfLogo/sheenplus/logo.png';
import solinteg_pdf from '@admin/assets/edition/pdfLogo/solinteg/logo.png';
import wattsonic_pdf from '@admin/assets/edition/pdfLogo/wattsonic/logo.png';
import metaess_pdf from '@admin/assets/edition/pdfLogo/metaess/logo.png';
import mtec_pdf from '@admin/assets/edition/pdfLogo/mtec/logo.png';
import aryagreen_pdf from '@admin/assets/edition/pdfLogo/aryagreen/logo.png';

// favicon
import favicon_solinteg from '@assets/edition/favicon/favicon_solinteg.ico';
import favicon_dowell from '@assets/edition/favicon/favicon_dowell.ico';
import favicon_etek from '@assets/edition/favicon/favicon_etek.ico';
import favicon_metaess from '@assets/edition/favicon/favicon_metaess.ico';
import favicon_mtec from '@assets/edition/favicon/favicon_mtec.ico';
import favicon_sheenplus from '@assets/edition/favicon/favicon_sheenplus.ico';
import favicon_wattsonic from '@assets/edition/favicon/favicon_wattsonic.ico';
import favicon_selfa from '@assets/edition/favicon/favicon_selfa.ico';
import favicon_aryagreen from '@assets/edition/favicon/favicon_aryagreen.ico';

import { dyLink } from './tools/base';

const windowPath = import.meta.env.MODE === 'dev' ? 'dev-portal.solinteg-cloud.com' : window.location.hostname;

let wattsonicPortal, etekPortal, metaEssPortal, mtecPortal, selfaPortal, sheenplusPortal, dowellPortal, aryaGreenPortal, solintegPortal;

const webSiteList = {
    solinteg: ['portal-cn.solinteg-cloud.com', 'portal-eu.solinteg-cloud.com'],
    metaess: ['portal-cn.meta-ess.com', 'portal-eu.meta-ess.com']
};

const setConstValue = () => {
    if (import.meta.env.MODE === 'dev' || import.meta.env.MODE === 'dev-portal' || import.meta.env.MODE === 'beta') {
        // wattsonic 中国
        wattsonicPortal = ['test-wattsonic.solinteg-cloud.com'];
        // etek 中国
        etekPortal = ['test-etek.solinteg-cloud.com'];
        // M-TEC
        mtecPortal = ['test-mtec.solinteg-cloud.com'];
        // Selfa
        selfaPortal = ['test-selfa.solinteg-cloud.com'];
        // SheenPlus
        sheenplusPortal = ['test-sheenplus.solinteg-cloud.com'];
        // Dowell
        dowellPortal = ['test-dowell.solinteg-cloud.com'];
        // ARYA GREEN
        aryaGreenPortal = ['test-aryagreen.solinteg-cloud.com'];
        // solinteg
        solintegPortal = [...['dev-portal.solinteg-cloud.com', 'test-portal.solinteg-cloud.com'], ...webSiteList.solinteg];
        // Solinteg版本中性`
        metaEssPortal = [...['test-metaess.solinteg-cloud.com'], ...webSiteList.metaess];
    }

    if (import.meta.env.MODE === 'release-cn' || import.meta.env.MODE === 'release-eu') {
        // wattsonic 中国
        wattsonicPortal = ['odm-wattsonic.solinteg-cloud.com', 'wattsonic.cloud'];
        // etek 中国
        etekPortal = ['odm-etek.solinteg-cloud.com', 'etek-monitoring.com'];
        // M-TEC
        mtecPortal = ['odm-mtec.solinteg-cloud.com', 'energybutler.mtec-portal.com'];
        // Selfa
        selfaPortal = ['odm-selfa.solinteg-cloud.com', 'hybrid.selfa-pv.com'];
        // SheenPlus
        sheenplusPortal = ['odm-sheenplus.solinteg-cloud.com', 'portal2.sheenplus.com'];
        // Dowell
        dowellPortal = ['odm-dowell.solinteg-cloud.com', 'monitor.dowellelectronic.com'];
        // ARYA GREEN
        aryaGreenPortal = ['odm-aryagreen.solinteg-cloud.com'];
        // solinteg
        solintegPortal = [...['portal.solinteg-cloud.com'], ...webSiteList.solinteg];
        // Solinteg版本中性`
        metaEssPortal = [...['portal.meta-ess.com', 'meta-ess.com'], ...webSiteList.metaess];
    }
};

setConstValue();

const odmStore = () => {
    if (wattsonicPortal.includes(windowPath)) {
        store.set('odm', 'WAT');
    }
    if (etekPortal.includes(windowPath)) {
        store.set('odm', 'ETE');
    }
    if (metaEssPortal.includes(windowPath)) {
        store.set('odm', 'NEU');
    }
    if (mtecPortal.includes(windowPath)) {
        store.set('odm', 'MTE');
    }
    if (selfaPortal.includes(windowPath)) {
        store.set('odm', 'SEL');
    }
    if (sheenplusPortal.includes(windowPath)) {
        store.set('odm', 'SHE');
    }
    if (solintegPortal.includes(windowPath)) {
        store.set('odm', 'SOL');
    }
    if (dowellPortal.includes(windowPath)) {
        store.set('odm', 'DOW');
    }
    if (aryaGreenPortal.includes(windowPath)) {
        store.set('odm', 'ARY');
    }
};

// 立即触发
odmStore();

// 每个odm定制的主题色
export const webSiteColors = () => {
    if (wattsonicPortal.includes(windowPath)) {
        return '#D3291E';
    }
    if (etekPortal.includes(windowPath)) {
        return '#FE5000';
    }
    if (metaEssPortal.includes(windowPath)) {
        return '#3582FA';
    }
    if (mtecPortal.includes(windowPath)) {
        return '#EEA42B';
    }
    if (selfaPortal.includes(windowPath)) {
        return '#0E6A38';
    }
    if (sheenplusPortal.includes(windowPath)) {
        return '#F15A24';
    }
    if (solintegPortal.includes(windowPath)) {
        return '#676CC4';
    }
    if (dowellPortal.includes(windowPath)) {
        return '#FF8F1C';
    }
    if (aryaGreenPortal.includes(windowPath)) {
        return '#407D56';
    }
};

import otherPowerStationSuccess from '@assets/edition/defaultImg/other/power-station-success.svg';
import otherEmptyDevice from '@assets/edition/defaultImg/other/emptyDevice.svg';
import otherEmptyStation from '@assets/edition/defaultImg/other/emptyStation.svg';
import otherEmptyNotice from '@assets/edition/defaultImg/other/notice-empty.svg';

import solintegPowerStationSuccess from '@assets/edition/defaultImg/solinteg/power-station-success.svg';
import solintegEmptyDevice from '@assets/edition/defaultImg/solinteg/emptyDevice.svg';
import solintegEmptyStation from '@assets/edition/defaultImg/solinteg/emptyStation.svg';
import solintegEmptyNotice from '@assets/edition/defaultImg/solinteg/notice-empty.svg';
// 配置默认图
export const defaultImg = () => {
    if (solintegPortal.includes(windowPath)) {
        return {
            defaultPowerStation: solintegPowerStationSuccess,
            defaultEmptyDevice: solintegEmptyDevice,
            defaultEmptyStation: solintegEmptyStation,
            defaultEmptyNotice: solintegEmptyNotice
        };
    }

    return {
        defaultPowerStation: otherPowerStationSuccess,
        defaultEmptyDevice: otherEmptyDevice,
        defaultEmptyStation: otherEmptyStation,
        defaultEmptyNotice: otherEmptyNotice
    };
};

// 返回是哪个版本
export const regionText = () => {
    if (wattsonicPortal.includes(windowPath)) {
        return 'wattsonic';
    }
    if (etekPortal.includes(windowPath)) {
        return 'etek';
    }
    if (metaEssPortal.includes(windowPath)) {
        return 'metaess';
    }
    if (sheenplusPortal.includes(windowPath)) {
        return 'sheenplus';
    }
    if (mtecPortal.includes(windowPath)) {
        return 'mtec';
    }
    if (selfaPortal.includes(windowPath)) {
        return 'selfa';
    }
    if (solintegPortal.includes(windowPath)) {
        return 'solinteg';
    }
    if (dowellPortal.includes(windowPath)) {
        return 'dowell';
    }
    if (aryaGreenPortal.includes(windowPath)) {
        return 'aryagreen';
    }
};

// app 下载二维码
import qrcode_dowell from '@admin/odm/dowell/qrcode_icon.png';
import qrcode_etek from '@admin/odm/etek/qrcode_icon.png';
import qrcode_metaess from '@admin/odm/metaess/qrcode_icon.png';
import qrcode_mtec from '@admin/odm/mtec/qrcode_icon.png';
import qrcode_selfa from '@admin/odm/selfa/qrcode_icon.png';
import qrcode_sheenplus from '@admin/odm/sheenplus/qrcode_icon.png';
import qrcode_solinteg from '@admin/odm/solinteg/qrcode_icon.png';
import qrcode_wattsonic from '@admin/odm/wattsonic/qrcode_icon.png';
import qrcode_aryagreen from '@admin/odm/aryagreen/qrcode_icon.png';
export const qrCode = () => {
    if (wattsonicPortal.includes(windowPath)) {
        return {
            icon: qrcode_wattsonic,
            appQrCodeUrl: `https://${windowPath}/download/mH8aT3.html`,
            title: regionText()
        };
    }
    if (etekPortal.includes(windowPath)) {
        return {
            icon: qrcode_etek,
            appQrCodeUrl: `https://${windowPath}/download/oV2oP4.html`,
            title: regionText()
        };
    }
    if (metaEssPortal.includes(windowPath)) {
        return {
            icon: qrcode_metaess,
            appQrCodeUrl: `https://${windowPath}/download/wQ3tE3.html`,
            title: regionText()
        };
    }
    if (sheenplusPortal.includes(windowPath)) {
        return {
            icon: qrcode_sheenplus,
            appQrCodeUrl: `https://${windowPath}/download/mG9kH8.html`,
            title: regionText()
        };
    }
    if (mtecPortal.includes(windowPath)) {
        return {
            icon: qrcode_mtec,
            appQrCodeUrl: `https://${windowPath}/download/qX7mD3.html`,
            title: regionText()
        };
    }
    if (selfaPortal.includes(windowPath)) {
        return {
            icon: qrcode_selfa,
            appQrCodeUrl: `https://${windowPath}/download/dH8eY9.html`,
            title: regionText()
        };
    }
    if (solintegPortal.includes(windowPath)) {
        return {
            icon: qrcode_solinteg,
            appQrCodeUrl: `https://${windowPath}/download/YeuBLM7.html`,
            title: regionText()
        };
    }
    if (dowellPortal.includes(windowPath)) {
        return {
            icon: qrcode_dowell,
            appQrCodeUrl: `https://${windowPath}/download/fN5mP1.html`,
            title: regionText()
        };
    }
    if (aryaGreenPortal.includes(windowPath)) {
        return {
            icon: qrcode_aryagreen,
            appQrCodeUrl: `https://${windowPath}/download/aryagreen.html`,
            title: 'arya green'
        };
    }
};

// 菜单双logo
import LogoImg_solinteg from '@odm/solinteg/logo.png';
import logo_pc_solinteg from '@odm/solinteg/logo_pc.svg';
import LogoImg_wattsonic from '@odm/wattsonic/logo.png';
import logo_pc_wattsonic from '@odm/wattsonic/logo_pc.svg';
import LogoImg_sheenplus from '@odm/sheenplus/logo.png';
import logo_pc_sheenplus from '@odm/sheenplus/logo_pc.svg';
import LogoImg_selfa from '@odm/selfa/logo.png';
import logo_pc_selfa from '@odm/selfa/logo_pc.svg';
import LogoImg_dowell from '@odm/dowell/logo.png';
import logo_pc_dowell from '@odm/dowell/logo_pc.svg';
import LogoImg_etek from '@odm/etek/logo.png';
import logo_pc_etek from '@odm/etek/logo_pc.svg';
import LogoImg_mtec from '@odm/mtec/logo.png';
import logo_pc_mtec from '@odm/mtec/logo_pc.svg';
import LogoImg_metaess from '@odm/metaess/logo.png';
import logo_pc_metaess from '@odm/metaess/logo_pc.svg';
import LogoImg_aryagreen from '@odm/aryagreen/logo.png';
import logo_pc_aryagreen from '@odm/aryagreen/logo_pc.svg';
export const menuLogo = () => {
    if (wattsonicPortal.includes(windowPath)) {
        return {
            singleLogo: LogoImg_wattsonic,
            s_width: '42px',
            s_height: '42px',
            longLogo: logo_pc_wattsonic
        };
    }
    if (etekPortal.includes(windowPath)) {
        return {
            singleLogo: LogoImg_etek,
            s_width: '50px',
            s_height: '50px',
            longLogo: logo_pc_etek
        };
    }
    if (metaEssPortal.includes(windowPath)) {
        return {
            singleLogo: LogoImg_metaess,
            s_width: '50px',
            s_height: '50px',
            longLogo: logo_pc_metaess
        };
    }
    if (mtecPortal.includes(windowPath)) {
        return {
            singleLogo: LogoImg_mtec,
            s_width: '50px',
            s_height: '50px',
            longLogo: logo_pc_mtec
        };
    }
    if (selfaPortal.includes(windowPath)) {
        return {
            singleLogo: LogoImg_selfa,
            s_width: '50px',
            s_height: '50px',
            longLogo: logo_pc_selfa
        };
    }
    if (sheenplusPortal.includes(windowPath)) {
        return {
            singleLogo: LogoImg_sheenplus,
            s_width: '50px',
            s_height: '50px',
            longLogo: logo_pc_sheenplus
        };
    }
    if (solintegPortal.includes(windowPath)) {
        return {
            singleLogo: LogoImg_solinteg,
            s_width: '53px',
            s_height: '24px',
            longLogo: logo_pc_solinteg
        };
    }
    if (dowellPortal.includes(windowPath)) {
        return {
            singleLogo: LogoImg_dowell,
            s_width: '50px',
            s_height: '50px',
            longLogo: logo_pc_dowell
        };
    }
    if (aryaGreenPortal.includes(windowPath)) {
        return {
            singleLogo: LogoImg_aryagreen,
            s_width: '50px',
            s_height: '50px',
            longLogo: logo_pc_aryagreen
        };
    }
};

// 逆变器机型图片
import inverter_solinteg from '@admin/odm/solinteg/invert.png';
import inverter_dowell from '@admin/odm/dowell/invert.png';
import inverter_etek from '@admin/odm/etek/invert.png';
import inverter_mtec from '@admin/odm/mtec/invert.png';
import inverter_selfa from '@admin/odm/selfa/invert.png';
import inverter_sheenplus from '@admin/odm/sheenplus/invert.png';
import inverter_wattsonic from '@admin/odm/wattsonic/invert.png';
import inverter_metaess from '@admin/odm/metaess/invert.png';
import inverter_aryagreen from '@admin/odm/aryagreen/invert.png';
export const inverterImg = () => {
    if (wattsonicPortal.includes(windowPath)) {
        return {
            inverter_img: inverter_wattsonic
        };
    }
    if (etekPortal.includes(windowPath)) {
        return {
            inverter_img: inverter_etek
        };
    }
    if (metaEssPortal.includes(windowPath)) {
        return {
            inverter_img: inverter_metaess
        };
    }
    if (sheenplusPortal.includes(windowPath)) {
        return {
            inverter_img: inverter_sheenplus
        };
    }
    if (mtecPortal.includes(windowPath)) {
        return {
            inverter_img: inverter_mtec
        };
    }
    if (selfaPortal.includes(windowPath)) {
        return {
            inverter_img: inverter_selfa
        };
    }
    if (solintegPortal.includes(windowPath)) {
        return {
            inverter_img: inverter_solinteg
        };
    }
    if (dowellPortal.includes(windowPath)) {
        return {
            inverter_img: inverter_dowell
        };
    }
    if (aryaGreenPortal.includes(windowPath)) {
        return {
            inverter_img: inverter_aryagreen
        };
    }
};

// CT检测图片
import ct_solinteg from '@odm/solinteg/ct_circle_bg.png';
import ct_wattsonic from '@odm/wattsonic/ct_circle_bg.png';
import ct_sheenplus from '@odm/sheenplus/ct_circle_bg.png';
import ct_selfa from '@odm/selfa/ct_circle_bg.png';
import ct_dowell from '@odm/dowell/ct_circle_bg.png';
import ct_etek from '@odm/etek/ct_circle_bg.png';
import ct_mtec from '@odm/mtec/ct_circle_bg.png';
import ct_metaess from '@odm/metaess/ct_circle_bg.png';
import ct_aryagreen from '@odm/aryagreen/ct_circle_bg.png';

import ct_wattsonic_inverter from '@odm/wattsonic/ct_wattsonic_inverter.svg';
import ct_mtec_inverter from '@odm/mtec/ct_mtec_inverter.svg';
import ct_etek_inverter from '@odm/etek/ct_etek_inverter.svg';
import ct_dowell_inverter from '@odm/dowell/ct_dowell_inverter.svg';
import ct_selfa_inverter from '@odm/selfa/ct_selfa_inverter.svg';
import ct_sheenplus_inverter from '@odm/sheenplus/ct_sheenplus_inverter.svg';
import ct_solinteg_inverter from '@odm/solinteg/ct_solinteg_inverter.svg';
import ct_metaess_inverter from '@odm/metaess/ct_metaess_inverter.svg';
import ct_aryagreen_inverter from '@odm/aryagreen/ct_aryagreen_inverter.svg';
export const ctBackGroundImg = () => {
    if (wattsonicPortal.includes(windowPath)) {
        return {
            bg: ct_wattsonic,
            inverter: ct_wattsonic_inverter
        };
    }
    if (etekPortal.includes(windowPath)) {
        return {
            bg: ct_etek,
            inverter: ct_etek_inverter
        };
    }
    if (metaEssPortal.includes(windowPath)) {
        return {
            bg: ct_metaess,
            inverter: ct_metaess_inverter
        };
    }
    if (mtecPortal.includes(windowPath)) {
        return {
            bg: ct_mtec,
            inverter: ct_mtec_inverter
        };
    }
    if (selfaPortal.includes(windowPath)) {
        return {
            bg: ct_selfa,
            inverter: ct_selfa_inverter
        };
    }
    if (sheenplusPortal.includes(windowPath)) {
        return {
            bg: ct_sheenplus,
            inverter: ct_sheenplus_inverter
        };
    }
    if (solintegPortal.includes(windowPath)) {
        return {
            bg: ct_solinteg,
            inverter: ct_solinteg_inverter
        };
    }
    if (dowellPortal.includes(windowPath)) {
        return {
            bg: ct_dowell,
            inverter: ct_dowell_inverter
        };
    }
    if (aryaGreenPortal.includes(windowPath)) {
        return {
            bg: ct_aryagreen,
            inverter: ct_aryagreen_inverter
        };
    }
};

// PDF导出上面使用的版权信息
export const corporation = () => {
    const local = store?.get('locale')?.slice(0, 2).toLowerCase();

    if (wattsonicPortal.includes(windowPath)) {
        return `Wattsonic Inc`;
    }
    if (etekPortal.includes(windowPath)) {
        return `ETEK Inc`;
    }
    if (metaEssPortal.includes(windowPath)) {
        return `MetaEss Inc`;
    }
    if (mtecPortal.includes(windowPath)) {
        return `M-TEC Inc`;
    }
    if (selfaPortal.includes(windowPath)) {
        return `Selfa`;
    }
    if (sheenplusPortal.includes(windowPath)) {
        return `SheenPlus Inc`;
    }
    if (solintegPortal.includes(windowPath)) {
        if (local === 'zh') return '无锡天青元储智能科技有限公司';
        return `Solinteg Inc`;
    }
    if (dowellPortal.includes(windowPath)) {
        return `Dowell Inc`;
    }
    if (aryaGreenPortal.includes(windowPath)) {
        return `TERMOIDRAULICA AGOSTINIE FIGLI - S.R.L.`;
    }
};

// login底部版权信息
import beianPng from '@assets/beian.png';
export const copyRight = (allRightReserved = 'All rights reserved.') => {
    const local = store?.get('locale')?.slice(0, 2).toLowerCase();

    if (wattsonicPortal.includes(windowPath)) {
        return (
            <>
                Copyright © 2023 {corporation()}. {allRightReserved} 版权所有：无锡旭浦能源科技有限公司
                <img
                    src={beianPng}
                    alt=""
                    style={{
                        height: '12px',
                        marginLeft: '5px',
                        marginRight: '5px',
                        marginBottom: '-2px'
                    }}
                />
                <a
                    href="https://beian.mps.gov.cn/#/query/webSearch?code=32021402002527"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        color: '#fff',
                        fontSize: '12px',
                        textDecoration: 'none'
                    }}>
                    苏公网安备32021402002527
                </a>
                <a
                    href="https://beian.miit.gov.cn/"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        color: '#fff',
                        fontSize: '12px',
                        marginLeft: '10px',
                        textDecoration: 'none'
                    }}>
                    苏ICP备19071352号-3
                </a>
            </>
        );
    }
    if (etekPortal.includes(windowPath)) {
        return `Copyright © 2022 ${corporation()}. ${allRightReserved}`;
    }
    if (metaEssPortal.includes(windowPath)) {
        return `Copyright © 2022 ${corporation()}. ${allRightReserved}`;
    }
    if (mtecPortal.includes(windowPath)) {
        return `Copyright © 2022 ${corporation()}. ${allRightReserved}`;
    }
    if (selfaPortal.includes(windowPath)) {
        return `Copyright © 2022 ${corporation()}. ${allRightReserved}`;
    }
    if (sheenplusPortal.includes(windowPath)) {
        return `Copyright © 2022 ${corporation()}. ${allRightReserved}`;
    }
    if (solintegPortal.includes(windowPath)) {
        if (local === 'zh') return `© 2022 ${corporation()}版权所有`;
        return `Copyright © 2022 ${corporation()}. ${allRightReserved}`;
    }
    if (dowellPortal.includes(windowPath)) {
        return `Copyright © 2022 ${corporation()}. ${allRightReserved}`;
    }
    if (aryaGreenPortal.includes(windowPath)) {
        return `Copyright © 2024 ${corporation()}. ${allRightReserved}`;
    }
};

export const copyRightColor = () => {
    if (wattsonicPortal.includes(windowPath)) {
        return '#ffffff';
    }
    if (etekPortal.includes(windowPath)) {
        return '#ffffff';
    }
    if (metaEssPortal.includes(windowPath)) {
        return '#000';
    }
    if (mtecPortal.includes(windowPath)) {
        return '#000';
    }
    if (selfaPortal.includes(windowPath)) {
        return '#000';
    }
    if (sheenplusPortal.includes(windowPath)) {
        return '#ffffff';
    }
    if (solintegPortal.includes(windowPath)) {
        return '#ffffff';
    }
    if (dowellPortal.includes(windowPath)) {
        return '#000';
    }
    if (aryaGreenPortal.includes(windowPath)) {
        return '#000';
    }
};

// PDF上的图片
export const pdfLogo = () => {
    if (wattsonicPortal.includes(windowPath)) {
        return {
            logo: wattsonic_pdf,
            width: 200,
            height: 32
        };
    }
    if (etekPortal.includes(windowPath)) {
        return {
            logo: etek_pdf,
            width: 120,
            height: 30
        };
    }
    if (metaEssPortal.includes(windowPath)) {
        return {
            logo: metaess_pdf,
            width: 150,
            height: 40
        };
    }
    if (mtecPortal.includes(windowPath)) {
        return {
            logo: mtec_pdf,
            width: 130,
            height: 35
        };
    }
    if (selfaPortal.includes(windowPath)) {
        return {
            logo: selfa_pdf,
            width: 70,
            height: 40
        };
    }
    if (sheenplusPortal.includes(windowPath)) {
        return {
            logo: sheenplus_pdf,
            width: 145,
            height: 35
        };
    }
    if (solintegPortal.includes(windowPath)) {
        return {
            logo: solinteg_pdf,
            width: 150,
            height: 18
        };
    }
    if (dowellPortal.includes(windowPath)) {
        return {
            logo: dowell_pdf,
            width: 155,
            height: 32
        };
    }
    if (aryaGreenPortal.includes(windowPath)) {
        return {
            logo: aryagreen_pdf,
            width: 155,
            height: 32
        };
    }
};

// 隐私政策，使用条款 地址
export const linkUrl = () => {
    const lang = store.get('locale').slice(0, 2).toLowerCase();

    if (wattsonicPortal.includes(windowPath)) {
        return {
            privacyPolicyUrl: `/f/wattsonic/privacyPolicy.html?locale=${lang}`,
            termUseUrl: `/f/wattsonic/termsOfUse.html?locale=${lang}`
        };
    }
    if (etekPortal.includes(windowPath)) {
        return {
            privacyPolicyUrl: `/f/etek/privacyPolicy.html?locale=${lang}`,
            termUseUrl: `/f/etek/termsOfUse.html?locale=${lang}`
        };
    }
    if (solintegPortal.includes(windowPath)) {
        return {
            privacyPolicyUrl: `/f/solinteg/privacyPolicy.html?locale=${lang}`,
            termUseUrl: `/f/solinteg/termsOfUse.html?locale=${lang}`
        };
    }
    if (metaEssPortal.includes(windowPath)) {
        return {
            privacyPolicyUrl: `/f/metaess/privacyPolicy.html?locale=${lang}`,
            termUseUrl: `/f/metaess/termsOfUse.html?locale=${lang}`
        };
    }
    if (mtecPortal.includes(windowPath)) {
        return {
            privacyPolicyUrl: `/f/mtec/privacyPolicy.html?locale=${lang}`,
            termUseUrl: `/f/mtec/termsOfUse.html?locale=${lang}`
        };
    }
    if (selfaPortal.includes(windowPath)) {
        return {
            privacyPolicyUrl: `/f/selfa/privacyPolicy.html?locale=${lang}`,
            termUseUrl: `/f/selfa/termsOfUse.html?locale=${lang}`
        };
    }
    if (sheenplusPortal.includes(windowPath)) {
        return {
            privacyPolicyUrl: `/f/sheenplus/privacyPolicy.html?locale=${lang}`,
            termUseUrl: `/f/sheenplus/termsOfUse.html?locale=${lang}`
        };
    }
    if (dowellPortal.includes(windowPath)) {
        return {
            privacyPolicyUrl: `/f/dowell/privacyPolicy.html?locale=${lang}`,
            termUseUrl: `/f/dowell/termsOfUse.html?locale=${lang}`
        };
    }
    if (aryaGreenPortal.includes(windowPath)) {
        return {
            privacyPolicyUrl: `/f/aryagreen/privacyPolicy.html?locale=${lang}`,
            termUseUrl: `/f/aryagreen/termsOfUse.html?locale=${lang}`
        };
    }
};

// odm 官网
export const odmWebPortal = () => {
    if (wattsonicPortal.includes(windowPath)) {
        return 'https://www.wattsonic.com';
    }
    if (etekPortal.includes(windowPath)) {
        return '';
    }
    if (metaEssPortal.includes(windowPath)) {
        return '';
    }
    if (mtecPortal.includes(windowPath)) {
        return 'https://www.mtec-systems.com"';
    }
    if (selfaPortal.includes(windowPath)) {
        return 'https://www.selfa-pv.com/';
    }
    if (sheenplusPortal.includes(windowPath)) {
        return 'https://www.sheenplus.com';
    }
    if (solintegPortal.includes(windowPath)) {
        return ''; // solinteg有自己的网站  区分多语言版本  此处直接为空就可以
    }
    if (dowellPortal.includes(windowPath)) {
        return 'https://www.dowellelectronic.com/';
    }
    if (aryaGreenPortal.includes(windowPath)) {
        return 'https://aryagreen.it/';
    }
};

// 设置每个ODM的多语言
export const i18nList = () => {
    return store.get('odm-list');
};

// 动态设置favicon
export const settingFavicon = async () => {
    if (wattsonicPortal.includes(windowPath)) {
        dyLink('/theme/wattsonic.css', favicon_wattsonic);
    }
    if (etekPortal.includes(windowPath)) {
        dyLink('/theme/etek.css', favicon_etek);
    }
    if (metaEssPortal.includes(windowPath)) {
        dyLink('/theme/metaEss.css', favicon_metaess);
    }
    if (mtecPortal.includes(windowPath)) {
        dyLink('/theme/mtec.css', favicon_mtec);
    }
    if (selfaPortal.includes(windowPath)) {
        dyLink('/theme/selfa.css', favicon_selfa);
    }
    if (sheenplusPortal.includes(windowPath)) {
        dyLink('/theme/sheenplus.css', favicon_sheenplus);
    }
    if (solintegPortal.includes(windowPath)) {
        dyLink('/theme/solinteg.css', favicon_solinteg);
    }
    if (dowellPortal.includes(windowPath)) {
        dyLink('/theme/dowell.css', favicon_dowell);
    }
    if (aryaGreenPortal.includes(windowPath)) {
        dyLink('/theme/aryagreen.css', favicon_aryagreen);
    }
};

// 流向图图标
import power_inverter_wattsonic from '@odm/wattsonic/power-inverter.svg';
import power_grid_wattsonic from '@odm/wattsonic/power-grid.svg';
import power_bettery_wattsonic from '@odm/wattsonic/power-bettery.svg';
import power_bettery_0_wattsonic from '@odm/wattsonic/power-bettery-0.svg';
import power_bettery_100_wattsonic from '@odm/wattsonic/power-bettery-100.svg';
import power_load_wattsonic from '@odm/wattsonic/power-load.svg';
import power_pv_wattsonic from '@admin/odm/wattsonic/power-pv.svg';
import power_third_wattsonic from '@odm/wattsonic/power-third.svg';
import power_inverter_gray_wattsonic from '@odm/wattsonic/power-inverter_gray.svg';

import power_inverter_etek from '@odm/etek/power-inverter.svg';
import power_grid_etek from '@odm/etek/power-grid.svg';
import power_bettery_etek from '@odm/etek/power-bettery.svg';
import power_bettery_0_etek from '@odm/etek/power-bettery-0.svg';
import power_bettery_100_etek from '@odm/etek/power-bettery-100.svg';
import power_load_etek from '@odm/etek/power-load.svg';
import power_pv_etek from '@admin/odm/etek/power-pv.svg';
import power_third_etek from '@odm/etek/power-third.svg';
import power_inverter_gray_etek from '@odm/etek/power-inverter_gray.svg';

import power_inverter_mtec from '@odm/mtec/power-inverter.svg';
import power_grid_mtec from '@odm/mtec/power-grid.svg';
import power_bettery_mtec from '@odm/mtec/power-bettery.svg';
import power_bettery_0_mtec from '@odm/mtec/power-bettery-0.svg';
import power_bettery_100_mtec from '@odm/mtec/power-bettery-100.svg';
import power_load_mtec from '@odm/mtec/power-load.svg';
import power_pv_mtec from '@admin/odm/mtec/power-pv.svg';
import power_third_mtec from '@odm/mtec/power-third.svg';
import power_inverter_gray_mtec from '@odm/mtec/power-inverter_gray.svg';

import power_inverter_selfa from '@odm/selfa/power-inverter.svg';
import power_grid_selfa from '@odm/selfa/power-grid.svg';
import power_bettery_selfa from '@odm/selfa/power-bettery.svg';
import power_bettery_0_selfa from '@odm/selfa/power-bettery-0.svg';
import power_bettery_100_selfa from '@odm/selfa/power-bettery-100.svg';
import power_load_selfa from '@odm/selfa/power-load.svg';
import power_pv_selfa from '@admin/odm/selfa/power-pv.svg';
import power_third_selfa from '@odm/selfa/power-third.svg';
import power_inverter_gray_selfa from '@odm/selfa/power-inverter_gray.svg';

import power_inverter_sheenplus from '@odm/sheenplus/power-inverter.svg';
import power_grid_sheenplus from '@odm/sheenplus/power-grid.svg';
import power_bettery_sheenplus from '@odm/sheenplus/power-bettery.svg';
import power_bettery_0_sheenplus from '@odm/sheenplus/power-bettery-0.svg';
import power_bettery_100_sheenplus from '@odm/sheenplus/power-bettery-100.svg';
import power_load_sheenplus from '@odm/sheenplus/power-load.svg';
import power_pv_sheenplus from '@admin/odm/sheenplus/power-pv.svg';
import power_third_sheenplus from '@odm/sheenplus/power-third.svg';
import power_inverter_gray_sheenplus from '@odm/sheenplus/power-inverter_gray.svg';

import power_inverter_solinteg from '@odm/solinteg/power-inverter.svg';
import power_grid_solinteg from '@odm/solinteg/power-grid.svg';
import power_bettery_solinteg from '@odm/solinteg/power-bettery.svg';
import power_bettery_0_solinteg from '@odm/solinteg/power-bettery-0.svg';
import power_bettery_100_solinteg from '@odm/solinteg/power-bettery-100.svg';
import power_load_solinteg from '@odm/solinteg/power-load.svg';
import power_pv_solinteg from '@admin/odm/solinteg/power-pv.svg';
import power_third_solinteg from '@odm/solinteg/power-third.svg';
import power_charge_solinteg from '@odm/solinteg/power-charge.svg';
import power_inverter_gray_solinteg from '@assets/svg/power-station-detail/station-inverter-gray.svg';

import power_inverter_dowell from '@odm/dowell/power-inverter.svg';
import power_grid_dowell from '@odm/dowell/power-grid.svg';
import power_bettery_dowell from '@odm/dowell/power-bettery.svg';
import power_bettery_0_dowell from '@odm/dowell/power-bettery-0.svg';
import power_bettery_100_dowell from '@odm/dowell/power-bettery-100.svg';
import power_load_dowell from '@odm/dowell/power-load.svg';
import power_pv_dowell from '@admin/odm/dowell/power-pv.svg';
import power_third_dowell from '@odm/dowell/power-third.svg';
import power_inverter_gray_dowell from '@odm/dowell/power-inverter_gray.svg';

import power_inverter_metaess from '@odm/metaess/power-inverter.svg';
import power_grid_metaess from '@odm/metaess/power-grid.svg';
import power_bettery_metaess from '@odm/metaess/power-bettery.svg';
import power_bettery_0_metaess from '@odm/metaess/power-bettery-0.svg';
import power_bettery_100_metaess from '@odm/metaess/power-bettery-100.svg';
import power_load_metaess from '@odm/metaess/power-load.svg';
import power_pv_metaess from '@admin/odm/metaess/power-pv.svg';
import power_third_metaess from '@odm/metaess/power-third.svg';
import power_inverter_gray_metaess from '@odm/metaess/power-inverter_gray.svg';

import power_inverter_aryagreen from '@odm/aryagreen/power-inverter.svg';
import power_grid_aryagreen from '@odm/aryagreen/power-grid.svg';
import power_bettery_aryagreen from '@odm/aryagreen/power-bettery.svg';
import power_bettery_0_aryagreen from '@odm/aryagreen/power-bettery-0.svg';
import power_bettery_100_aryagreen from '@odm/aryagreen/power-bettery-100.svg';
import power_load_aryagreen from '@odm/aryagreen/power-load.svg';
import power_pv_aryagreen from '@admin/odm/aryagreen/power-pv.svg';
import power_third_aryagreen from '@odm/aryagreen/power-third.svg';
import power_inverter_gray_aryagreen from '@odm/aryagreen/power-inverter_gray.svg';

import power_bettery_gray from '@assets/svg/power-station-detail/station-bettery-gray.svg';
import power_bettery_0_gray from '@assets/svg/power-station-detail/station-battery-gray.svg';
import power_bettery_100_gray from '@assets/svg/power-station-detail/station-bettery-gray100.svg';
import power_load_gray from '@assets/svg/power-station-detail/station-consumption-gray.svg';
import power_charge_gray from '@assets/svg/power-station-detail/station-charge-gray.svg';
import power_grid_gray from '@assets/svg/power-station-detail/station-electric-gray.svg';
import power_pv_gray from '@assets/svg/power-station-detail/station-photovoltaic-gray.svg';
import power_third_gray from '@assets/svg/power-station-detail/station-third-gray.svg';
export const powerStationIcon = () => {
    if (wattsonicPortal.includes(windowPath)) {
        return {
            pvNode: power_pv_wattsonic,
            thirdNode: power_third_wattsonic,
            meterNode: power_grid_wattsonic,
            batteryNode: power_bettery_wattsonic,
            batteryNode_0: power_bettery_0_wattsonic,
            batteryNode_100: power_bettery_100_wattsonic,
            loadNode: power_load_wattsonic,
            chargeNode: power_charge_solinteg,
            inverterNode: power_inverter_wattsonic,
            betteryGray: power_bettery_gray,
            betteryGray0: power_bettery_0_gray,
            betteryGray100: power_bettery_100_gray,
            loadGray: power_load_gray,
            chargeGray: power_charge_gray,
            meterGray: power_grid_gray,
            inverterGray: power_inverter_gray_wattsonic,
            pvGray: power_pv_gray,
            thirdGray: power_third_gray
        };
    }
    if (etekPortal.includes(windowPath)) {
        return {
            pvNode: power_pv_etek,
            thirdNode: power_third_etek,
            meterNode: power_grid_etek,
            batteryNode: power_bettery_etek,
            batteryNode_0: power_bettery_0_etek,
            batteryNode_100: power_bettery_100_etek,
            loadNode: power_load_etek,
            chargeNode: power_charge_solinteg,
            inverterNode: power_inverter_etek,
            betteryGray: power_bettery_gray,
            betteryGray0: power_bettery_0_gray,
            betteryGray100: power_bettery_100_gray,
            loadGray: power_load_gray,
            chargeGray: power_charge_gray,
            meterGray: power_grid_gray,
            inverterGray: power_inverter_gray_etek,
            pvGray: power_pv_gray,
            thirdGray: power_third_gray
        };
    }
    if (metaEssPortal.includes(windowPath)) {
        return {
            pvNode: power_pv_metaess,
            thirdNode: power_third_metaess,
            meterNode: power_grid_metaess,
            batteryNode: power_bettery_metaess,
            batteryNode_0: power_bettery_0_metaess,
            batteryNode_100: power_bettery_100_metaess,
            loadNode: power_load_metaess,
            chargeNode: power_charge_solinteg,
            inverterNode: power_inverter_metaess,
            betteryGray: power_bettery_gray,
            betteryGray0: power_bettery_0_gray,
            betteryGray100: power_bettery_100_gray,
            loadGray: power_load_gray,
            chargeGray: power_charge_gray,
            meterGray: power_grid_gray,
            inverterGray: power_inverter_gray_metaess,
            pvGray: power_pv_gray,
            thirdGray: power_third_gray
        };
    }
    if (sheenplusPortal.includes(windowPath)) {
        return {
            pvNode: power_pv_sheenplus,
            thirdNode: power_third_sheenplus,
            meterNode: power_grid_sheenplus,
            batteryNode: power_bettery_sheenplus,
            batteryNode_0: power_bettery_0_sheenplus,
            batteryNode_100: power_bettery_100_sheenplus,
            loadNode: power_load_sheenplus,
            chargeNode: power_charge_solinteg,
            inverterNode: power_inverter_sheenplus,
            betteryGray: power_bettery_gray,
            betteryGray0: power_bettery_0_gray,
            betteryGray100: power_bettery_100_gray,
            loadGray: power_load_gray,
            chargeGray: power_charge_gray,
            meterGray: power_grid_gray,
            inverterGray: power_inverter_gray_sheenplus,
            pvGray: power_pv_gray,
            thirdGray: power_third_gray
        };
    }
    if (mtecPortal.includes(windowPath)) {
        return {
            pvNode: power_pv_mtec,
            thirdNode: power_third_mtec,
            meterNode: power_grid_mtec,
            batteryNode: power_bettery_mtec,
            batteryNode_0: power_bettery_0_mtec,
            batteryNode_100: power_bettery_100_mtec,
            loadNode: power_load_mtec,
            chargeNode: power_charge_solinteg,
            inverterNode: power_inverter_mtec,
            betteryGray: power_bettery_gray,
            betteryGray0: power_bettery_0_gray,
            betteryGray100: power_bettery_100_gray,
            loadGray: power_load_gray,
            chargeGray: power_charge_gray,
            meterGray: power_grid_gray,
            inverterGray: power_inverter_gray_mtec,
            pvGray: power_pv_gray,
            thirdGray: power_third_gray
        };
    }
    if (selfaPortal.includes(windowPath)) {
        return {
            pvNode: power_pv_selfa,
            thirdNode: power_third_selfa,
            meterNode: power_grid_selfa,
            batteryNode: power_bettery_selfa,
            batteryNode_0: power_bettery_0_selfa,
            batteryNode_100: power_bettery_100_selfa,
            loadNode: power_load_selfa,
            chargeNode: power_charge_solinteg,
            inverterNode: power_inverter_selfa,
            betteryGray: power_bettery_gray,
            betteryGray0: power_bettery_0_gray,
            betteryGray100: power_bettery_100_gray,
            loadGray: power_load_gray,
            chargeGray: power_charge_gray,
            meterGray: power_grid_gray,
            inverterGray: power_inverter_gray_selfa,
            pvGray: power_pv_gray,
            thirdGray: power_third_gray
        };
    }
    if (solintegPortal.includes(windowPath)) {
        return {
            pvNode: power_pv_solinteg,
            thirdNode: power_third_solinteg,
            meterNode: power_grid_solinteg,
            batteryNode: power_bettery_solinteg,
            batteryNode_0: power_bettery_0_solinteg,
            batteryNode_100: power_bettery_100_solinteg,
            loadNode: power_load_solinteg,
            chargeNode: power_charge_solinteg,
            inverterNode: power_inverter_solinteg,
            betteryGray: power_bettery_gray,
            betteryGray0: power_bettery_0_gray,
            betteryGray100: power_bettery_100_gray,
            loadGray: power_load_gray,
            chargeGray: power_charge_gray,
            meterGray: power_grid_gray,
            inverterGray: power_inverter_gray_solinteg,
            pvGray: power_pv_gray,
            thirdGray: power_third_gray
        };
    }
    if (dowellPortal.includes(windowPath)) {
        return {
            pvNode: power_pv_dowell,
            thirdNode: power_third_dowell,
            meterNode: power_grid_dowell,
            batteryNode: power_bettery_dowell,
            batteryNode_0: power_bettery_0_dowell,
            batteryNode_100: power_bettery_100_dowell,
            loadNode: power_load_dowell,
            chargeNode: power_charge_solinteg,
            inverterNode: power_inverter_dowell,
            betteryGray: power_bettery_gray,
            betteryGray0: power_bettery_0_gray,
            betteryGray100: power_bettery_100_gray,
            loadGray: power_load_gray,
            chargeGray: power_charge_gray,
            meterGray: power_grid_gray,
            inverterGray: power_inverter_gray_dowell,
            pvGray: power_pv_gray,
            thirdGray: power_third_gray
        };
    }
    if (aryaGreenPortal.includes(windowPath)) {
        return {
            pvNode: power_pv_aryagreen,
            thirdNode: power_third_aryagreen,
            meterNode: power_grid_aryagreen,
            batteryNode: power_bettery_aryagreen,
            batteryNode_0: power_bettery_0_aryagreen,
            batteryNode_100: power_bettery_100_aryagreen,
            loadNode: power_load_aryagreen,
            chargeNode: power_charge_solinteg,
            inverterNode: power_inverter_aryagreen,
            betteryGray: power_bettery_gray,
            betteryGray0: power_bettery_0_gray,
            betteryGray100: power_bettery_100_gray,
            loadGray: power_load_gray,
            chargeGray: power_charge_gray,
            meterGray: power_grid_gray,
            inverterGray: power_inverter_gray_aryagreen,
            pvGray: power_pv_gray,
            thirdGray: power_third_gray
        };
    }
};
