import axios from 'axios';
import { serverUrl, libUrl } from '../server';
import { message } from 'antd';
import store from 'store';
import { API_TIMEOUT } from '../constant';
import UUIDjs from 'uuid-js';

const instance = axios.create({
    timeout: API_TIMEOUT,
    retry: 3, // 设置全局重试请求次数（最多重试几次请求）
    retryDelay: 1000 // 设置全局请求间隔
    // baseURL: serverUrl()
});

instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
instance.defaults.headers['Cache-Control'] = 'no-cache';

// 设置http状态码
const httpCode = {
    301: '资源(网页等)被永久转移到其它URL',
    400: '请求参数错误',
    401: '权限不足, 请重新登录',
    403: '服务器拒绝本次访问',
    404: '请求资源未找到',
    500: '内部服务器错误',
    501: '服务器不支持该请求中使用的方法',
    502: '网关错误',
    504: '网关超时'
};

/** 添加请求拦截器 **/
instance.interceptors.request.use(
    config => {
        const token = store.get('token');
        const lang = store.get('locale');
        const odm = store.get('odm');
        const offset = new Date().getTimezoneOffset();
        const requestId = UUIDjs._create1()?.hex;

        Object.assign(config.headers, { token, offset, requestId, var: 'pc', odm, plant: 'admin' });
        if (!config.headers.lang) {
            Object.assign(config.headers, { lang });
        }

        const regx = /^https?:\/\//;

        if (!regx.test(config.url)) {
            if (config.url.includes('/meta_host') || config.url.includes('/heartBeatV2')) {
                config.url = libUrl() + config.url;
            } else {
                config.url = serverUrl() + config.url;
            }
        }

        return config;
    },
    error => {
        // 请求错误调用reject返回错误信息
        return Promise.reject(error);
    }
);

/** 添加响应拦截器  **/
instance.interceptors.response.use(
    response => {
        if (response.status === 210) {
            window.location.href = `/maintenance.html?redirect=${encodeURIComponent(window.location.pathname)}`;
        }

        if (response && response.data && (response.data.isSuccessful === false || response.data.successful === false)) {
            if (response.data.info && response.data.info.code === 'error.10004') {
                // 未登录
                store.remove('token'); // 删除 token（过期token）
                if (location.pathname !== '/login') {
                    history.pushState(null, null, '/login');
                }
            } else if (!response.config.url.includes('/pc/remote/recvCmdResult')) {
                // 参数设置回包机制不提示信息
                message.error(response.data.info.description || 'request fail');
            }

            return Promise.reject(response.data.info) && Promise.resolve(response.data);
        }
        return Promise.resolve(response.data);
    },
    error => {
        const { code, config, response, message: msg } = error;

        // 超时处理 config是一个对象，包含上方create中设置的三个必要参数
        if (!config || !config.retry) {
            return Promise.reject(error);
        }

        // __retryCount用来记录当前是第几次发送请求
        config.__retryCount = config.__retryCount || 0;

        // URL为上传接口的不使用自动发起请求
        if (config.url.indexOf('/oss/_uploadFile') !== -1) {
            if (error.message === 'Network Error') {
                // 网络连接中断处理
                return Promise.reject({ type: 'warning', msg: '网络连接已断开，请检查网络' });
            } else if (code === 'ECONNABORTED') {
                message.error('请求超时，请检查网络');
                // 连接超时处理
                return Promise.reject({ type: 'warning', msg: '请求超时，请检查网络' });
            }

            // 除以上两种状态外的情况  在httpCode中
            const tips = response.status in httpCode ? httpCode[response.status] : response.data.msg;

            return Promise.reject({ type: 'error', msg: tips || msg });
        }

        // 如果当前发送的请求大于等于设置好的请求次数时，不再发送请求，返回最终的错误信息
        if (config.__retryCount >= config.retry) {
            // 如果重试4此依旧是服务不通  不管什么接口  进入错误页面  有好提示

            // if (error.message === 'Network Error') {
            //     // 网络连接中断处理
            //     return Promise.reject({ type: 'warning', msg: '网络连接已断开，请检查网络' });
            // } else if (code === 'ECONNABORTED') {
            //     // 连接超时处理
            //     return Promise.reject({ type: 'warning', msg: '请求超时，请检查网络' });
            // }
            //
            // 除以上两种状态外的情况  在httpCode中
            const tips = response.status in httpCode ? httpCode[response.status] : response.data.msg;

            return Promise.reject({ type: 'error', msg: tips || msg });
        }

        // 记录请求次数+1
        config.__retryCount += 1;

        // 设置请求间隔 在发送下一次请求之前停留一段时间，时间为上方设置好的请求间隔时间
        const backoff = new Promise(function (resolve) {
            setTimeout(function () {
                resolve();
            }, config.retryDelay || 1);
        });

        // 再次发送请求
        return backoff.then(function () {
            return instance(config);
        });
    }
);

const cancelTokenSources = {};
const getRequestIdentifier = (url, params) => {
    if (url.includes('/pc/organization')) {
        const newUrl = url.replace(/[0-9]/g, 'o');

        return instance.getUri({
            url: newUrl
        });
    }

    if (url.includes('/pc/user/check/steps')) {
        return instance.getUri({
            url,
            params: {
                time: new Date().getTime()
            }
        });
    }

    // 使用带参数的 URL 作为标识
    return instance.getUri({
        url,
        params
    });
};

/* 统一封装get请求 */
export const TQ_Get = (url, params, headers) => {
    // 生成唯一标识
    const requestIdentifier = getRequestIdentifier(url, params);

    // 如果存在相同标识的请求，则取消之前的请求
    if (cancelTokenSources[requestIdentifier]) {
        cancelTokenSources[requestIdentifier].cancel('取消之前的请求');
    }

    // 创建新的 CancelToken
    const cancelTokenSource = axios.CancelToken.source();

    // 存储当前请求的 CancelToken
    cancelTokenSources[requestIdentifier] = cancelTokenSource;

    return new Promise((resolve, reject) => {
        instance({
            method: 'get',
            url,
            params,
            headers,
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('请求被取消', error.message, 'URL:', url);
                } else {
                    reject(error);
                }
            })
            .finally(() => {
                // 请求完成后删除该请求的标识
                delete cancelTokenSources[requestIdentifier];
            });
    });
};

/* 统一封装post请求  */
export const TQ_Post = (url, data, params, headers) => {
    // 生成唯一标识
    const requestIdentifier = getRequestIdentifier(url, data);

    // 如果存在相同标识的请求，则取消之前的请求
    if (cancelTokenSources[requestIdentifier]) {
        cancelTokenSources[requestIdentifier].cancel('取消之前的请求');
    }

    // 创建新的 CancelToken
    const cancelTokenSource = axios.CancelToken.source();

    // 存储当前请求的 CancelToken
    cancelTokenSources[requestIdentifier] = cancelTokenSource;
    return new Promise((resolve, reject) => {
        instance({
            method: 'post',
            url,
            data,
            params,
            headers,
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('请求被取消', error.message, 'URL:', url);
                } else {
                    reject(error);
                }
            })
            .finally(() => {
                // 请求完成后删除该请求的标识
                delete cancelTokenSources[requestIdentifier];
            });
    });
};

/* 统一封装put请求  */
export const TQ_Put = (url, data, params, headers) => {
    // 生成唯一标识
    const requestIdentifier = getRequestIdentifier(url, data);

    // 如果存在相同标识的请求，则取消之前的请求
    if (cancelTokenSources[requestIdentifier]) {
        cancelTokenSources[requestIdentifier].cancel('取消之前的请求');
    }

    // 创建新的 CancelToken
    const cancelTokenSource = axios.CancelToken.source();

    // 存储当前请求的 CancelToken
    cancelTokenSources[requestIdentifier] = cancelTokenSource;
    return new Promise((resolve, reject) => {
        instance({
            method: 'put',
            url,
            data,
            params,
            headers,
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('请求被取消', error.message, 'URL:', url);
                } else {
                    reject(error);
                }
            })
            .finally(() => {
                // 请求完成后删除该请求的标识
                delete cancelTokenSources[requestIdentifier];
            });
    });
};

/* 统一封装delete请求 */
export const TQ_Delete = (url, params, headers) => {
    // 生成唯一标识
    const requestIdentifier = getRequestIdentifier(url, params);

    // 如果存在相同标识的请求，则取消之前的请求
    if (cancelTokenSources[requestIdentifier]) {
        cancelTokenSources[requestIdentifier].cancel('取消之前的请求');
    }

    // 创建新的 CancelToken
    const cancelTokenSource = axios.CancelToken.source();

    // 存储当前请求的 CancelToken
    cancelTokenSources[requestIdentifier] = cancelTokenSource;
    return new Promise((resolve, reject) => {
        instance({
            method: 'delete',
            url,
            params,
            headers,
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('请求被取消', error.message, 'URL:', url);
                } else {
                    reject(error);
                }
            })
            .finally(() => {
                // 请求完成后删除该请求的标识
                delete cancelTokenSources[requestIdentifier];
            });
    });
};
